import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import {Typography} from "@material-ui/core";
import { useOnlineAvatarStyles } from '@mui-treasury/styles/avatar/online';
import {withStyles} from "@material-ui/core";

import {styles} from "../../common/styles";

const AvatarPresentation: React.FC<{}> = React.memo(
    function OnlineAvatar(props) {
        const { classes } = props;
        const styles = useOnlineAvatarStyles({
            color: '#f05a99',
            thickness: 2,
            gap: 0,
        });
        return (
            <Box className={classes.root}>
                <div className={styles.root}>
                    <Avatar src={'https://i.pravatar.cc/300'} />
                </div>
                <Typography className={classes.onlineAvatar}>
                    Hey, My Name is Justin Djeumene!
                </Typography>
            </Box>
        );
    }
);

export default withStyles(styles)(AvatarPresentation)
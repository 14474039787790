import React, {useRef} from 'react'
import {Container, Grid, Typography, Button, Hidden} from "@material-ui/core";
import {withStyles} from "@material-ui/core";
import {styles} from "../../common/styles";
import Grids from '../abstractions/Grids'
import useTyped from "use-typed/src";

const Jumbotron = (props) => {
    const {classes} = props;
    const wordRef = useRef(null);
    useTyped(wordRef, {
        strings: [
            '<span>Associations</span> ',
            '<span>Communities</span>',
            '<span>Immigrants</span>',
            '<span>Students</span>',
            '<span>Staffs</span>',
            '<span>LGBTQ+</span>',
            '<span>Any groups</span>',
        ],
        typeSpeed: 80,
        backSpeed: 40,
        backDelay: 1000,
        loop: true,
    })
    return (
        <Container maxWidth={'xl'}>
            <Grids type={"column"} spacing={4} className={classes.container_jumbotron}>
                <Grid item={true} xs={12} sm={12} md={12}>
                    <Typography className={classes.asso_jumbotron} align={"center"}>
                        we power <span ref={wordRef} style={{color: "#0452d2"}}/><br/> more than ever!
                    </Typography>
                </Grid>
                <Grid item={true} xs={12} sm={10} lg={8} xl={6}>
                    <Hidden smDown={true}>
                        <Typography align={"center"} variant={'h6'} >
                            Discover the end-to-end encrypted platform that gives you more than what you expect in terms of productivity,
                            the ease to work efficiently, to manage events, chat, fundraising, surveys, forms, Accounting Suite and more...
                        </Typography>
                    </Hidden>
                    <Hidden mdUp={true}>
                        <Typography align={"center"} variant={'h6'}>
                            Discover the secure platform that boosts your productivity.
                        </Typography>
                    </Hidden>
                </Grid>
                <Grid item xs={12}>
                    <Button href={'/start'} variant={"contained"} color={"primary"} size={"large"} className={classes.heroButton}>Get Started</Button>
                </Grid>
            </Grids>
        </Container>
    )
}

export default withStyles(styles)(Jumbotron);
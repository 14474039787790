import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
        maxWidth: 500,
        borderRadius: 0,
    },
    media: {
        minHeight: 300,
        borderRadius: 2,
    },
});

export default function CardPresentation(props) {
    const classes = useStyles();
    const {myTitle, image, content} = props;
    return (
        <Card className={classes.root} elevation={0}>
            <CardActionArea disabled={true}>
                <CardMedia
                    className={classes.media}
                    image={image}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2" className={classes.subtitle}>
                        {myTitle}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {content}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

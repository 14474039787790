import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from '@material-ui/icons/Check';
import green from "@material-ui/core/colors/green";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function Free(props) {
    const {website, events, chat, member} = props;
    const classes = useStyles();

    return (
        <List subheader={<ListSubheader>Includes</ListSubheader>} className={classes.root}>
            <ListItem>
                <ListItemIcon>
                    <CheckIcon style={{ color: green[500] }}/>
                </ListItemIcon>
                <ListItemText id="website" primary={website} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CheckIcon style={{ color: green[500] }} />
                </ListItemIcon>
                <ListItemText id="Events Management" primary={events} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CheckIcon style={{ color: green[500] }} />
                </ListItemIcon>
                <ListItemText id="Chat" primary={chat} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CheckIcon style={{ color: green[500] }} />
                </ListItemIcon>
                <ListItemText id="membership management" primary={member}/>
            </ListItem>
        </List>
    );
}
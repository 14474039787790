import React, {Fragment} from 'react';
import {Container, Grid, Typography, Button} from '@material-ui/core'
import AssoTabsTemplate from "./AssoTabsTemplate";
import {withStyles} from "@material-ui/core";
import {styles} from "../../common/styles";


const AssoFeatures = props => {
    const {classes} = props;
    return (
       <Fragment>
           <div className={classes.features_root}>
               <Container maxWidth={'lg'}>
                   <Grid container={true}>
                       <Grid item={true} xs={12}>
                           <Typography variant={'h4'} className={classes.features_title}>
                               Features
                           </Typography>
                       </Grid>
                       <Grid item={true} xs={12}>
                           <Typography variant={'h6'} className={classes.features_text}>
                               Discover all the features you have in your instance.
                           </Typography>
                       </Grid>
                       <Grid item={true} xs={12} className={classes.features_try} >
                           <Button size={'large'} variant={'contained'} color={"secondary"}> Try TitiAsso Now</Button>
                       </Grid>
                   </Grid>
               </Container>
           </div>
           <Container maxWidth={'lg'} style={{marginTop: '-48px'}}>
               <AssoTabsTemplate/>
           </Container>
       </Fragment>
    )
}

export default withStyles(styles)(AssoFeatures);
import React from 'react'
import {Container, Grid, Typography} from "@material-ui/core";
import {Link} from 'react-router-dom';
import {withStyles} from "@material-ui/core";
import {Logo} from '../../common/images/index'
import Grids from '../abstractions/Grids'
import {Support} from '../forms'
import {styles} from '../../common/styles'
import {facebook,linkedin, twitter} from '../../common/images'

const Footer = (props) => {
    const {classes} = props;
    return (
        <Container maxWidth={'xl'} className={classes.root_footer}>
            <Grids type={"rowS"} spacing={2}>
                <Grid item xs={6} md={2} ld={2} type={'column'}>
                    <Typography align={"left"} variant={'h6'} className={classes.footerSubtitle}>
                        Company
                    </Typography>
                    <Typography align={"left"}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                            About Us
                        </Link>
                    </Typography>
                    <Typography align={"left"}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                            Jobs
                        </Link>
                    </Typography>
                    <Typography align={"left"}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                            Terms of use
                        </Link>
                    </Typography>
                    <Typography align={"left"}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                           Privacy Policy
                        </Link>
                    </Typography>
                    <Typography align={"left"}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                            Contact Us
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={6} md={2} ld={2} type={'column'}>
                    <Typography align={"left"} variant={'h6'} className={classes.subtitle}>
                        Features
                    </Typography>
                    <Typography align={"left"}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                           Templates
                        </Link>
                    </Typography>
                    <Typography align={"left"}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                           Events Management
                        </Link>
                    </Typography>
                    <Typography align={"left"}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                            Chat
                        </Link>
                    </Typography>
                    <Typography align={"left"}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                            Fundraising
                        </Link>
                    </Typography>
                    <Typography align={"left"}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                            Surveys
                        </Link>
                    </Typography>
                    <Typography align={"left"}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                           Accounting Suite
                        </Link>
                    </Typography>
                    <Typography align={"left"}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                            Jobs Posting
                        </Link>
                    </Typography>
                    <Typography align={"left"}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                           Members Management
                        </Link>
                    </Typography>
                    <Typography align={"left"}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                           Forms Center
                        </Link>
                    </Typography>
                    <Typography align={"left"}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                            Tontine
                        </Link>
                    </Typography>
                    <Typography align={"left"} gutterBottom={true}>
                        <Link to={'/'} className={classes.allTextDecoration}>
                            Advertising
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3} type={'column'} className={classes.footer_support}>
                    <Typography align={"center"} variant={'h6'} gutterBottom={true} className={classes.subtitle}>
                        Support Center
                    </Typography>
                    <Support/>
                </Grid>
                <Grid item xs={12} md={3} ld={2} type={'column'}>
                    <Typography align={"left"} gutterBottom={true}>
                        <Logo/>
                    </Typography>
                    <Typography align={"left"} className={classes.footer_titi_text}>
                        titiAsso App is a digital platform that offers SAAS (Software As A Service) through a web, mobile and desktop app,
                        to manage efficiently all kinds of associations or communities (Staff, Students, LGBTQ+, immigrants, professional ones, groups, and more).
                    </Typography>
                    <a href={'https://facebook.com'}>
                        <img src={facebook} alt={'facebook'}/>
                    </a>
                    <a href={'https://twitter.com'}>
                        <img src={twitter} alt={'twitter'}/>
                    </a>
                    <a href={'https://linkedin.com'}>
                        <img src={linkedin} alt={'linkedin'}/>
                    </a>
                </Grid>
            </Grids>
            <Grids type={'row'} xs={12} md={4}>
                <Typography gutterBottom={true} style={{color: '#1e2128'}}>
                    Copyright &copy; titiasso app Inc 2020.
                </Typography>
            </Grids>
        </Container>
    )
}

export default withStyles(styles)(Footer);
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import CheckIcon from "@material-ui/icons/Check";
import green from "@material-ui/core/colors/green";
import ListItemIcon from "@material-ui/core/ListItemIcon";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function AssoPricingOptions(props) {
    const {surveys, forms, accounting, support} = props;
    const classes = useStyles();


    return (
        <List subheader={<ListSubheader>Includes</ListSubheader>} className={classes.root}>
            <ListItem>
                <ListItemIcon>
                    <CheckIcon style={{ color: green[500] }} />
                </ListItemIcon>
                <ListItemText id="surveys" primary={surveys} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CheckIcon style={{ color: green[500] }} />
                </ListItemIcon>
                <ListItemText id="forms center" primary={forms} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CheckIcon style={{ color: green[500] }} />
                </ListItemIcon>
                <ListItemText id="accounting suite" primary={accounting} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <CheckIcon style={{ color: green[500] }} />
                </ListItemIcon>
                <ListItemText id="support technique" primary={support} />
            </ListItem>
        </List>
    );
}
import React from 'react'
import {MenuItem} from "@material-ui/core";


const AssoMenuItems = (props) => {
    return (
        <MenuItem>
            {props.children}
        </MenuItem>
    )
}

export default AssoMenuItems;
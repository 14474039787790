import React from 'react'
import {Container} from "@material-ui/core";
import {element} from "prop-types";

const Content = props => {
    return (
        <Container maxWidth={'xl'}>
            {props.children}
        </Container>
    )
}

Content.propTypes = {
    children: element
}

export default Content;
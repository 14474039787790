import {atom, selector} from "recoil/dist";

export let differentPricing = atom( {
    key: 'differentPricing',
    default: {
        Select_option: false,
        free: false,
        starter: false,
        premium: false,
        ultimate: false,
        small_business: false,
    }
})

export let createOrganization = atom({
    key: 'createOrganization',
    default: {
        name: '',
        domain: '',
        username: '',
        email: '',
        password: '',
        confirm_password: '',
        showPassword: false,
    }
})

export let valueSelected = atom({
    key: 'valueSelected',
    default: {
        option: 'Select_option'
    }
})

export const getValueSelected = selector({
    key: 'getValueSelected',
    get: ({get}) => get(valueSelected),
    set: ({set}, newValue) => set(valueSelected, newValue)
})

export const getPricing = selector({
    key: 'getPricing',
    get: ({get}) => get(differentPricing),
    set: ({set}, newValue) => set(differentPricing, newValue)
})

export const getCreateOrganization = selector({
    key: 'getCreateOrganization',
    get: ({get}) => ({...get(createOrganization), angelo: 'fabriker'}),
    set: ({set}, newValue) => set(createOrganization, newValue)
})


import React, {Fragment, useEffect} from 'react'
import {Container, Grid, Typography, Card} from "@material-ui/core";
import AssoPublicNavBar from "../header/public_website/AssoPublicNavBar";
import {withStyles} from "@material-ui/core";
import {styles} from "../../common/styles";
import AssoStepper from "./AssoStepper";
import {useRecoilValue} from "recoil/dist";
import {getValueSelected, getCreateOrganization} from "../../GlobalState";
import CheckedRecap from "./CheckedRecap";
import {recap} from '../../common/images'
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Paper from "@material-ui/core/Paper";

const SignUp = props => {
    const {classes} = props;
    const organization = useRecoilValue(getCreateOrganization);
    const mySelection = useRecoilValue(getValueSelected);
    const domain = (`https://${organization.domain}.assoapp.com`);
    const free = (`Your current option is  free and the total invoice is $0`);
    const starter = (`Your current option is starter and the total invoice is US 120.00 yearly`);
    const premium = (`Your current option is premium and the total invoice is US 300.00 yearly`);
    const ultimate = (`Your current option is ultimate and the total invoice is US 900.00 yearly`);
    const small_business = (`Your total current option is small business and the invoice is US 600.00 yearly`)

    useEffect(() => {

    })
    return (
        <Fragment>
            <AssoPublicNavBar/>
            <div className={classes.create_root}>
                <Container maxWidth={'lg'}>
                    <Grid container={true}>
                        <Grid item={true} xs={12}>
                            <Typography variant={'h4'} className={classes.pricing_title}>
                                Create Your Organization
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Typography variant={'h6'} className={classes.pricing_text}>
                                Please follow those 3 easy steps below.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container maxWidth={'lg'}>
                <Grid container={true} spacing={4} className={classes.root_create}>
                    <Grid item={true} xs={12} sm={6} md={6}>
                        <Card variant={'outlined'} elevation={0} className={classes.steps} raised={true}>
                            <Typography>
                                Just 5mn you're good to go.
                            </Typography>
                            <AssoStepper/>
                        </Card>
                    </Grid>
                    { organization.name === '' ? " " :
                    <Grid item={true} xs={12} sm={6} md={6} className={classes.recap}>
                        <Card variant={'outlined'} elevation={0} className={classes.infoRecap} raised={true}>
                            <img src={recap} alt={''} width={200} className={classes.image_recap}/>
                            <div>
                                <Typography variant={'overline'} align={'left'} className={classes.recap_title}> <strong>HERE IS YOUR SUMMARY </strong></Typography>
                            </div>
                            {organization.name === '' ? "" :
                                <List subheader={<ListSubheader className={classes.important_title}>Important</ListSubheader>}>
                                    <CheckedRecap chosen={`Organization Name : ${organization.name}`}/>

                                    {organization.domain === '' ? ' ' :
                                        <CheckedRecap chosen={`Your Subdomain: ${domain}`}/>
                                    }
                                    {mySelection.option === 'free' ?
                                        <CheckedRecap chosen={free}/> :
                                        mySelection.option === 'starter' ?
                                            <CheckedRecap chosen={starter}/> :
                                            mySelection.option === 'premium' ?
                                                <CheckedRecap chosen={premium}/> :
                                                mySelection.option === 'ultimate' ?
                                                    <CheckedRecap chosen={ultimate}/> :
                                                    mySelection.option === 'Select_option' ? " " :
                                                        <CheckedRecap chosen={small_business}/>
                                    }
                                </List>
                            }
                            { organization.domain === '' ? " " :
                            <Paper className={classes.recap_paper} elevation={0}>
                                <Typography align={'center'}>
                                   Your platform is hosted at <br/> https://{organization.domain}.assoapp.com
                                </Typography>
                            </Paper>
                          }
                        </Card>
                    </Grid>
                    }
                </Grid>
            </Container>
        </Fragment>

    )
}

export default withStyles(styles)(SignUp);
import React, {Fragment} from 'react'
import {Container, Grid, Typography} from "@material-ui/core";
import Grids from '../abstractions/Grids'
import CardDetails from "./CardDetails";
import {AccountingPic} from '../../common/images'


const Accounting = (props) => {
    return (
        <Fragment>
            <div style={{backgroundColor: '#f0f0f0', color: '#1e2128'}}>
                <Container maxWidth={'xl'} >
                    <Grids type={'columnL'} md={12} style={{paddingLeft: '128px'}}>
                        <Typography variant={'h5'} style={{marginTop: '72px'}}>
                            Complete Accounting Suite
                        </Typography>
                        <Typography variant={'overline'}>
                            Manage all your financial stuffs easily with our Accounting Suite
                        </Typography>
                    </Grids>
                    <Grids type={'rowS'} md={12}>
                        <img src={AccountingPic} alt={'Accounting'} style={{margin: '25px', border: "1px #303238 solid", width: "65%"}} />
                    </Grids>
                </Container>
                <Container>
                    <Grids type={'row'} lg={12} spacing={8}>
                        <Grid  xs={12} sm={6} md={6} item={true}>
                            <CardDetails myTitle={'Accounts Payable'}  content={'Processing all kinds of invoices (debit, credit) and expenses reports, create suppliers, ' +
                            'validate invoice, adjust discounts and freights, prepayments, taxes, miscellaneous, pay invoices and accounting flows to the General Ledger.'}/>
                        </Grid>
                        <Grid  xs={12} sm={6} md={6} item={true}>
                            <CardDetails myTitle={'Accounts Receivable'}  content={'Create and enter customers with their addresses, produces invoices to mail to customers, posting' +
                            'payments as they are received, define credit terms and invoicing requirements, email receipts to customers, apply payment.'}/>
                        </Grid>
                     </Grids>
                 </Container>
                <Container>
                     <Grids type={'row'} lg={12} spacing={1}>
                            <Grid  xs={12} sm={6} md={6} item={true}>
                                <CardDetails myTitle={'Invoices'}  content={'Setup customers and create all kinds of invoices (debit and credit), print or emails invoices to customers' +
                                'create, print or email purchase orders, apply discounts and freight, modify, update, delete and save invoices, ... a full invoicing cycle.'}/>
                            </Grid>
                            <Grid  xs={12} sm={6} md={6} item={true}>
                                <CardDetails myTitle={'Assets'}  content={'Process the full assets cycle flow, create a group of asset, add, adjust, depreciate and retire assets from your ' +
                                'accounting system, records all assets transaction in your journal entries, automate monthly assets depreciation.'}/>
                            </Grid>
                     </Grids>
                </Container>
                <Container>
                    <Grids type={'row'} lg={12} spacing={1}>
                        <Grid  xs={12} sm={6} md={6} item={true}>
                            <CardDetails myTitle={'Cash Management'}  content={'Match all financial recordings (cash, banks) with your banking statement or cash statement' +
                            'and what you really have in your accounting system, adjust cash or bank transactions, print reconciliation statements for bank and cash forecasting. '}/>
                        </Grid>
                        <Grid  xs={12} sm={6} md={6} item={true}>
                            <CardDetails myTitle={'Inventory'}  content={'Manage inventory for all requiring orders, record all inventories directly in your General Ledger, create order scheduling,' +
                            ' manage orders and record everything in your journal entry.'}/>
                        </Grid>
                    </Grids>
                </Container>
                <Container>
                    <Grids type={'row'} lg={12} spacing={1}>
                        <Grid  xs={12} sm={6} md={6} item={true}>
                            <CardDetails myTitle={'GL, Balance Sheet, Statements'}  content={'Record all transactions in your General Ledger, adjust or print your balance sheet, income statements' +
                            'automating recurring transactions in GL and more.'}/>
                        </Grid>
                        <Grid  xs={12} sm={6} md={6} item={true}>
                            <CardDetails myTitle={'Reports Center'}  content={'Your place to print all kinds of reports about your Accounting Suite, invoices, suppliers statements, customers' +
                            'statements, journal entries statements, balance sheet, and more.'}/>
                        </Grid>
                    </Grids>
                </Container>
            </div>
        </Fragment>
    )
}

export default Accounting;
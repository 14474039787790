import React from 'react'
import {Menu} from "@material-ui/core";
import {useState} from 'react'
import AssoMenuItems from "../AssoMenuItems";
import {withStyles} from "@material-ui/styles";
import {styles} from "../../../common/styles";
import IconButton from "@material-ui/core/IconButton";
import LanguageIcon from '@material-ui/icons/Language';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import MoreIcon from "@material-ui/icons/MoreVert";
import {Logo} from '../../../common/images/index'
import {Link} from 'react-router-dom'

const AssoPublicNavBar = (props) => {
    const {classes} = props;
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <AssoMenuItems onClick={handleMenuClose}>French</AssoMenuItems>
            <AssoMenuItems onClick={handleMenuClose}>Spanish</AssoMenuItems>
        </Menu>
    );

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <AssoMenuItems>
                <Typography >
                    <Link to={'/features'} className={classes.allTextDecoration}>Features</Link>
                </Typography>
            </AssoMenuItems>
            <AssoMenuItems>
                <Typography>
                    <Link to={'/pricing'} className={classes.allTextDecoration}>Pricing</Link>
                </Typography>
            </AssoMenuItems>
        </Menu>
    )
    return (
        <div className={classes.app_root}>
            <CssBaseline/>
            <AppBar position="static" elevation={0} style={{backgroundColor: '#fff'}}>
                <Toolbar variant={'regular'} disableGutters={true}>
                    <Typography className={classes.titleBar} noWrap>
                        <Logo/>
                    </Typography>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktopBar}>
                        <Typography className={classes.elementBar}>
                            <Link to={'/features'} className={classes.allTextDecoration}>Features</Link>
                        </Typography>
                        <Typography className={classes.elementBar}>
                            <Link to={'/pricing'} className={classes.allTextDecoration}>Pricing</Link>
                        </Typography>
                        <IconButton
                            edge="end"
                            aria-label="language"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                        >
                            <LanguageIcon/>
                        </IconButton>
                        <Typography className={classes.langElementBar + " " + classes.allTextDecoration}>
                            EN
                        </Typography>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMenu}
            {renderMobileMenu}
        </div>
    )
}


export default withStyles(styles)(AssoPublicNavBar);
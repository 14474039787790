import React from 'react'
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";


function GetStepContent({step, handleChange, myOrganization, handleClickShowPassword}) {
    let result = null;
    switch (step) {
        case 0:
            result = <Step1 handleChange={handleChange} myOrganization={myOrganization}/>;
            break;
        case 1:
            result = <Step2  handleClickShowPassword={handleClickShowPassword} handleChange={handleChange} myOrganization={myOrganization}/>;
            break;
        case 2:
            result = <Step3/>;
            break;
        default:
            throw new Error('Unknown step');
    }
    return result;
}

export default GetStepContent;
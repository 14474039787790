import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FeatureHeader from "./FeatureHeader";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function ScrollableTabsButtonAuto() {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="primary" elevation={0} >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"

                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto features"
                >
                    <Tab label="Websites" {...a11yProps(0)} />
                    <Tab label="Events Management" {...a11yProps(1)} />
                    <Tab label="Chat" {...a11yProps(2)} />
                    <Tab label="Fundraising" {...a11yProps(3)} />
                    <Tab label="Surveys" {...a11yProps(4)} />
                    <Tab label="Accounting Suite" {...a11yProps(5)} />
                    <Tab label="Jobs Posting" {...a11yProps(6)} />
                    <Tab label="Members Management" {...a11yProps(7)} />
                    <Tab label="Forms Center" {...a11yProps(8)} />
                    <Tab label="Tontine" {...a11yProps(9)} />
                    <Tab label="Advertising" {...a11yProps(10)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <FeatureHeader title={'Websites'} content={'We have multiple pre-made website templates easily customizable you can ' +
                'choose from and add your own stuffs. We are keeping adding new ones every week to meet your goals.'}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
               <FeatureHeader title={'Events Management'} content={'This service primary focuses on all events management aspect including' +
               'tickets, budget and price calculator, speakers management, sponsorship and more.'}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <FeatureHeader title={'Chat'} content={'Create private chat rooms or chat directly with your community or group members 24/7.'}/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <FeatureHeader title={'Fundraising'} content={'Great causes that matter need great engagements, so create and share easily a ' +
                'fundraising for what you find right.'}/>
            </TabPanel>
            <TabPanel value={value} index={4}>
                Item Five
            </TabPanel>
            <TabPanel value={value} index={5}>
                Item Six
            </TabPanel>
            <TabPanel value={value} index={6}>
                Item Seven
            </TabPanel>
            <TabPanel value={value} index={7}>
                Item Eight
            </TabPanel>
            <TabPanel value={value} index={8}>
                Item Nine
            </TabPanel>
            <TabPanel value={value} index={9}>
                Item Ten
            </TabPanel>
            <TabPanel value={value} index={10}>
                Item Eleven
            </TabPanel>
        </div>
    );
}

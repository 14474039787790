import React from 'react'
import {Grid, Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/core";
import {styles} from "../../common/styles";
import TemplateShows from "./TemplateShows";

const Templates = (props) => {
    const {classes} = props;
    return (
        <div className={classes.grow}>
            <Grid container className={classes.template_root}>
                <Grid container item xs={12} sm={12} md={6}>
                    <Grid item xs={12} md={12} className={classes.template_title}>
                        <Typography variant={'h5'} className={classes.template_titleText}>
                            PRE-MADE TEMPLATES TO CHOOSE FOR YOUR PUBLIC WEBSITE
                        </Typography>
                        <Typography>
                            All templates have the following features:
                        </Typography>
                    </Grid>
                    <Grid container={true}>
                        <Grid xs={12} sm={6} md={6} className={classes.template_text} item={true}>
                            <hr className={classes.template_hr}/>
                            <Typography variant={'h6'} className={classes.template_titles}>
                                Customize your website
                            </Typography>
                            <Typography>
                                You can change everything of your public website from an easy to use
                                and nice dashboard tailored to fit your needs.
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} md={6} className={classes.template_text} item={true}>
                            <hr className={classes.template_hr}/>
                            <Typography variant={'h6'} className={classes.template_titles}>
                                Mobile friendly
                            </Typography>
                            <Typography>
                                All your interfaces are responsible making easier to work from
                                mobile, tablets and still getting the same users experience.
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} md={6} className={classes.template_text} item={true}>
                            <hr className={classes.template_hr}/>
                            <Typography variant={'h6'} className={classes.template_titles}>
                                Search Engine Optimized
                            </Typography>
                            <Typography>
                                All our templates are already optimized for SEO and secured
                                making your browser experiences good.
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} md={6} className={classes.template_text} item={true}>
                            <hr className={classes.template_hr}/>
                            <Typography variant={'h6'} className={classes.template_titles}>
                                Advanced features on demand
                            </Typography>
                            <Typography>
                                We guarantee to listen in case you ever want to add any
                                advanced features (online store, blog, services and more).
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={6}>
                    <TemplateShows/>

                </Grid>
            </Grid>
        </div>

    )
}

export default withStyles(styles)(Templates);
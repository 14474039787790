import React from 'react'
import {element} from "prop-types";
import {Container, Grid, Typography} from '@material-ui/core'
import {withStyles} from "@material-ui/core";
import {styles} from "../../common/styles";

const FeatureHeader = props => {
    const {title, content, classes} = props;
    return (
        <Container maxWidth={'lg'}>
            <Grid container={true}>
                <Grid item={true} xs={12}>
                    <Typography variant={'h4'} className={classes.feature_header}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item={true} xs={12} sm={8} md={6}>
                    <Typography className={classes.feature_content}>
                        {content}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}
FeatureHeader.propTypes = {
    children: element
}

export default withStyles(styles)(FeatureHeader);
import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import GetStepContent from "./Recap";
import {createOrganization, getCreateOrganization} from '../../GlobalState'
import{useRecoilState} from "recoil/dist";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

function getSteps() {
    return ['Organization Name', 'Your account (Admin already)', 'Payment'];
}



export default function AssoStepper() {
    const classes = useStyles();
    const [myOrganization, setOrganization] = useRecoilState(getCreateOrganization);
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();



    const handleClickShowPassword = () => {
        setOrganization({ ...myOrganization, showPassword: !myOrganization.showPassword });
    };

    useEffect( () => {

        console.log(myOrganization)
    })

    const handleChange = name => event => {
        setOrganization({ ...myOrganization, [name]: event.target.value });
        console.log(createOrganization)
    };

    const handleNext = () => {
        if(myOrganization.name === ""){
            setActiveStep(activeStep );
        }
        else if(myOrganization.domain === ""){
            setActiveStep(activeStep );
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    const handleSubmit = e => {
        e.preventDefault();
    };

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            <form onSubmit={handleSubmit}>
                                <GetStepContent
                                    step={index}
                                    handleChange={handleChange}
                                    myOrganization={myOrganization}
                                    handleClickShowPassword={handleClickShowPassword}
                                />
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.button}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            disableElevation={true}
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                        >
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>All steps completed - thank you for signing up</Typography>
                    <Button onClick={handleReset} className={classes.button} disableElevation={true}>
                        Reset
                    </Button>
                </Paper>
            )}
        </div>
    );
}

import React from 'react';
import NoWhere from "../components/Error/404";
import {Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/core";
import {styles} from "../common/styles";
import {Link} from 'react-router-dom';

const Page404 = (props) => {
    const { classes } = props;
    return (
        <div className={classes.page404}>
            <Typography className={classes.nowhere} variant={'h1'}>
                404
            </Typography>
            <Typography className={classes.nowheres} variant={'h5'}>
                Page Not Found!
            </Typography>
            <NoWhere/>
            <Typography className={classes.nowherelink}>
                 <Link to={'/'} className={classes.allTextDecoration404}>
                     Go back home
                 </Link>
            </Typography>
        </div>
    )
}


export default withStyles(styles)(Page404);
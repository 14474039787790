import React, {useRef} from 'react'
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


const Step2 = ({handleChange, myOrganization, handleClickShowPassword}) => {
    const username = useRef();
    const email = useRef();
    const password = useRef();
    const confirm_password = useRef();
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <div>
            <FormControl  variant="filled" margin={'dense'} fullWidth={true}>
                <InputLabel htmlFor="filled-adornment-password">Username</InputLabel>
                <FilledInput
                    ref={username}
                    onChange={handleChange('username')}
                    value={myOrganization.username}
                    required={true}
                    type={'text'}
                />
            </FormControl> <br/>
            <FormControl  variant="filled" margin={'dense'} fullWidth={true}>
                <InputLabel htmlFor="filled-adornment-password">Email</InputLabel>
                <FilledInput
                   ref={email}
                    onChange={handleChange('email')}
                    value={myOrganization.email}
                    required={true}
                    type={'email'}
                    placeholder={'for eg. nurse'}
                />
            </FormControl>
            <FormControl  variant="filled" margin={'dense'} fullWidth={true}>
                <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                <FilledInput
                    ref={password}
                    label={'Password'}
                    onChange={handleChange('password')}
                    value={myOrganization.password}
                    required={true}
                    type={myOrganization.showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {myOrganization.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <FormControl  variant="filled"  margin={'dense'} fullWidth={true}>
                <InputLabel htmlFor="filled-adornment-password">Confirm Password</InputLabel>
                <FilledInput
                    ref={confirm_password}
                    label={'Confirm Password'}
                    variant={'outlined'}
                    onChange={handleChange('confirm_password')}
                    value={myOrganization.confirm_password}
                    required={true}
                    type={myOrganization.showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {myOrganization.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </div>
    )
}

export default Step2;
import React from 'react'
import {Container, Typography, Grid, TextField, Button} from "@material-ui/core";
import Grids from '../abstractions/Grids'

const Support = () => {
    return (
        <Container>
            <Grids type={'column'} md={12}>
                <Typography gutterBottom={true}> Open a ticket?</Typography>
                <Grid item={true} xs={12}>
                    <form method={'post'}>
                        <TextField
                            variant={'outlined'}
                            label={'Association Number'}
                            type={'number'}
                            autoComplete={'off'}
                            fullWidth={true}
                            margin={'normal'}
                            required={true}
                        />
                        <TextField
                            id="outlined-multiline-static"
                            label="Concerns"
                            multiline
                            rows={4}
                            defaultValue="What bothers you ...."
                            variant="outlined"
                            fullWidth={true}
                            margin={'normal'}
                        />
                        <Button
                            type={'submit'}
                            color={'primary'}
                            variant={'contained'}
                            size={'medium'}
                            fullWidth={true}
                        >
                            Send
                        </Button>
                    </form>
                </Grid>
            </Grids>
        </Container>
    )
}

export default Support;
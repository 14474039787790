import React from 'react';

const Logo = props => {
    return (
        <svg width="98px" height="24px" viewBox="0 0 98 24">
            <g id="Remade-Logo-Copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Logo" transform="translate(-1.000000, -1.000000)">
                    <g id="app" transform="translate(60.000000, 6.513146)" fill="#1E2128">
                        <path d="M19.5,0.446997419 C22.7383969,0.446997419 25.3775718,3.01257231 25.4958615,6.22205916 L25.5,6.44699742 L25.5,8.44699742 C25.5,11.7607059 22.8137085,14.4469974 19.5,14.4469974 C18.6079074,14.4469974 17.7612872,14.2523071 17.0003108,13.9030978 L17,17.4868544 L13.5,17.4868544 L13.5,0.486854419 L17,0.486854419 L17.0003108,0.990897021 C17.7612872,0.641687746 18.6079074,0.446997419 19.5,0.446997419 Z M19.5,3.94699742 C18.1192881,3.94699742 17,5.06628554 17,6.44699742 L17,8.44699742 C17,9.82770929 18.1192881,10.9469974 19.5,10.9469974 C20.8807119,10.9469974 22,9.82770929 22,8.44699742 L22,6.44699742 C22,5.06628554 20.8807119,3.94699742 19.5,3.94699742 Z" id="Combined-Shape"></path>
                        <path d="M33,0.486854419 C36.2383969,0.486854419 38.8775718,3.05242931 38.9958615,6.26191616 L39,6.48685442 L39,8.48685442 C39,11.8005629 36.3137085,14.4868544 33,14.4868544 C32.1079074,14.4868544 31.2612872,14.2921641 30.5003108,13.9429548 L30.5,17.5267114 L27,17.5267114 L27,0.526711419 L30.5,0.526711419 L30.5003108,1.03075402 C31.2612872,0.681544746 32.1079074,0.486854419 33,0.486854419 Z M33,3.98685442 C31.6192881,3.98685442 30.5,5.10614254 30.5,6.48685442 L30.5,8.48685442 C30.5,9.86756629 31.6192881,10.9868544 33,10.9868544 C34.3807119,10.9868544 35.5,9.86756629 35.5,8.48685442 L35.5,6.48685442 C35.5,5.10614254 34.3807119,3.98685442 33,3.98685442 Z" id="Combined-Shape-Copy"></path>
                        <path d="M11.9962717,4.80701797 C11.998757,4.87133896 12,4.93570195 12,5.00007094 L12,12.454 L12.25,14.4469977 L8.75,14.4469977 L8.68849632,13.9617393 C8.21282461,14.0535086 7.73271919,14.1237258 7.24963821,14.1720339 L4.5,14.4469977 C2.290861,14.4469977 0.5,12.6561367 0.5,10.4469977 C0.5,8.23785869 2.290861,6.44699769 4.5,6.44699769 L9,6.44699769 L9,5.23890924 C9,4.24043963 8.26359796,3.39492937 7.27458318,3.25784788 L7.0912323,3.2324347 C5.93337169,3.0719505 4.79134444,3.61727057 4.18825701,4.61861063 L4.02984619,4.88162904 L1.2010498,3.85019593 C1.56864511,1.84563627 3.21469288,0.323958291 5.24189939,0.114627226 C5.64246464,0.0732703546 6.04392787,0.0418485101 6.44628906,0.0203616927 C6.56491242,0.0140269904 6.68513225,0.0085061837 6.80694855,0.0037992725 C9.56631239,-0.102819356 11.8896515,2.04765419 11.9962717,4.80701797 Z M8.9,8.82370668 L5.20422363,8.82370668 C4.47982182,8.82370668 3.89257812,9.41095037 3.89257812,10.1353522 C3.89257812,10.9571097 4.50218063,11.6513444 5.31704261,11.7575773 C5.35899817,11.763047 5.40039168,11.7680257 5.44122314,11.7725134 C5.73549331,11.8048563 7.25886249,11.7277115 7.86969503,11.556575 L7.98583984,11.5184699 L7.98583984,11.5184699 C8.59766606,11.2780817 9.00001047,10.6877138 9,10.0303571 L9,8.92370668 C9,8.8684782 8.95522847,8.82370668 8.9,8.82370668 Z" id="a"></path>
                    </g>
                    <g id="asso" transform="translate(0.500000, 0.513146)">
                        <rect id="Rectangle" fill="#1E2128" x="0.5" y="0.486854419" width="56.5" height="24" rx="2"></rect>
                        <path d="M47,6.44699742 C50.3137085,6.44699742 53,9.13328892 53,12.4469974 L53,12.4469974 L53,14.4469974 C53,17.7607059 50.3137085,20.4469974 47,20.4469974 C43.6862915,20.4469974 41,17.7607059 41,14.4469974 L41,14.4469974 L41,12.4469974 C41,9.13328892 43.6862915,6.44699742 47,6.44699742 Z M47,9.94699742 C45.6192881,9.94699742 44.5,11.0662855 44.5,12.4469974 L44.5,14.4469974 C44.5,15.8277093 45.6192881,16.9469974 47,16.9469974 C48.3807119,16.9469974 49.5,15.8277093 49.5,14.4469974 L49.5,12.4469974 C49.5,11.0662855 48.3807119,9.94699742 47,9.94699742 Z" id="o" fill="#FFFFFF"></path>
                        <path d="M20.3,10.7546897 C20.3,11.3494579 20.7821549,11.8316128 21.3769231,11.8316128 L24.15,11.8316128 C26.2762963,11.8316128 28,13.5553165 28,15.6816128 L28,16.1393051 C28,18.5183779 26.0713805,20.4469974 23.6923077,20.4469974 L19.6394036,20.4469974 C18.6526236,20.4469974 17.7051159,20.0603976 17,19.3700743 L17,19.3700743 L17,19.3700743 L17.55,16.1393051 C17.8875545,16.8002509 18.5670869,17.2162282 19.3092408,17.2162282 L23.6230769,17.2162282 C24.2178451,17.2162282 24.7,16.7340733 24.7,16.1393051 C24.7,15.5445369 24.2178451,15.062382 23.6230769,15.062382 L20.85,15.062382 C18.7237037,15.062382 17,13.3386783 17,11.212382 L17,10.7546897 C17,8.37561696 18.9286195,6.44699742 21.3076923,6.44699742 L24.8105964,6.44699742 C25.7973764,6.44699742 26.7448841,6.83359723 27.45,7.5239205 L27.45,7.5239205 L27.45,7.5239205 L26.9,10.2162282 C26.547442,9.87106656 26.0736882,9.67776665 25.5802982,9.67776665 L21.3769231,9.67776665 C20.7821549,9.67776665 20.3,10.1599215 20.3,10.7546897 Z" id="s2" fill="#FFFFFF"></path>
                        <path d="M32.3,10.7546897 C32.3,11.3494579 32.7821549,11.8316128 33.3769231,11.8316128 L36.15,11.8316128 C38.2762963,11.8316128 40,13.5553165 40,15.6816128 L40,16.1393051 C40,18.5183779 38.0713805,20.4469974 35.6923077,20.4469974 L31.6394036,20.4469974 C30.6526236,20.4469974 29.7051159,20.0603976 29,19.3700743 L29,19.3700743 L29,19.3700743 L29.55,16.1393051 C29.8875545,16.8002509 30.5670869,17.2162282 31.3092408,17.2162282 L35.6230769,17.2162282 C36.2178451,17.2162282 36.7,16.7340733 36.7,16.1393051 C36.7,15.5445369 36.2178451,15.062382 35.6230769,15.062382 L32.85,15.062382 C30.7237037,15.062382 29,13.3386783 29,11.212382 L29,10.7546897 C29,8.37561696 30.9286195,6.44699742 33.3076923,6.44699742 L36.8105964,6.44699742 C37.7973764,6.44699742 38.7448841,6.83359723 39.45,7.5239205 L39.45,7.5239205 L39.45,7.5239205 L38.9,10.2162282 C38.547442,9.87106656 38.0736882,9.67776665 37.5802982,9.67776665 L33.3769231,9.67776665 C32.7821549,9.67776665 32.3,10.1599215 32.3,10.7546897 Z" id="s1" fill="#FFFFFF"></path>
                        <path d="M15.9962717,10.807018 C15.998757,10.871339 16,10.9357019 16,11.0000709 L16,18.454 L16.25,20.4469977 L12.75,20.4469977 L12.6884963,19.9617393 C12.2128246,20.0535086 11.7327192,20.1237258 11.2496382,20.1720339 L8.5,20.4469977 C6.290861,20.4469977 4.5,18.6561367 4.5,16.4469977 C4.5,14.2378587 6.290861,12.4469977 8.5,12.4469977 L13,12.4469977 L13,11.2389092 C13,10.2404396 12.263598,9.39492937 11.2745832,9.25784788 L11.0912323,9.2324347 C9.93337169,9.0719505 8.79134444,9.61727057 8.18825701,10.6186106 L8.02984619,10.881629 L5.2010498,9.85019593 C5.56864511,7.84563627 7.21469288,6.32395829 9.24189939,6.11462723 C9.64246464,6.07327035 10.0439279,6.04184851 10.4462891,6.02036169 C10.5649124,6.01402699 10.6851322,6.00850618 10.8069486,6.00379927 C13.5663124,5.89718064 15.8896515,8.04765419 15.9962717,10.807018 Z M12.9,14.8237067 L9.20422363,14.8237067 C8.47982182,14.8237067 7.89257812,15.4109504 7.89257812,16.1353522 C7.89257812,16.9571097 8.50218063,17.6513444 9.31704261,17.7575773 C9.35899817,17.763047 9.40039168,17.7680257 9.44122314,17.7725134 C9.73549331,17.8048563 11.2588625,17.7277115 11.869695,17.556575 L11.9858398,17.5184699 L11.9858398,17.5184699 C12.5976661,17.2780817 13.0000105,16.6877138 13,16.0303571 L13,14.9237067 C13,14.8684782 12.9552285,14.8237067 12.9,14.8237067 Z" id="a" fill="#FFFFFF"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
};

export default Logo;
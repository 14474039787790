import React from 'react'
import {AssoPublicNavBar, Footer, AssoPricing} from '../components'




const Pricing = props => {
    return (
        <div style={{ overflowX: 'hidden'}}>
            <AssoPublicNavBar/>
            <AssoPricing/>
            <Footer/>
        </div>
    )
}

export default Pricing;
import React, {Fragment} from 'react';
import {Container, Grid, Typography} from '@material-ui/core'
import {withStyles} from "@material-ui/core";
import {styles} from "../../common/styles";
import AssoPricingCard from "./AssoPricingCard";
import Free from "./pricing/Free";
import Starter from "./pricing/Starter";
import Premium from "./pricing/Premium";
import Ultimate from "./pricing/Ultimate";
import SmallBusiness from "./pricing/SmallBusiness";
import {premium, ultimate, starter, small_business, free} from '../../common/images/'


const AssoPricing = props => {
    const {classes} = props;
    return (
        <Fragment>
            <div className={classes.pricing_root}>
                <Container maxWidth={'lg'}>
                    <Grid container={true}>
                        <Grid item={true} xs={12}>
                            <Typography variant={'h4'} className={classes.pricing_title}>
                                Pricing
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Typography variant={'h6'} className={classes.pricing_text}>
                               Choose the option that meets your requirements.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <Container maxWidth={'lg'}>
                <Grid container={true} spacing={4}>
                    <Grid item={true} xs={12} sm={6} md={3}>
                        <AssoPricingCard image={free} alt={'free'} title={'Free for ever!'} price={'$0'} button={'Start Now'} name={'free'}/>
                        <Free website={'Website (5 pages max.)'} events={'Events management'} chat={'Chat'}
                                            member={'Membership Management (7 members max.)'}/>
                    </Grid>
                    <Grid item={true} xs={12} sm={6} md={3}>
                        <AssoPricingCard image={starter} alt={'starter'} title={'Starter'} price={'$10'} button={'Buy Now'} name={'starter'}/>
                        <Starter website={'Website (10 pages max.)'} events={'Events management'} chat={'Chat'}
                                            member={'Membership Management (10 members max.)'} fundraising={'Fundraising'} jobs={'Jobs Posting'}/>
                    </Grid>
                    <Grid item={true} xs={12} sm={6} md={3}>
                        <AssoPricingCard image={premium} alt={'premium'} title={'Premium'} price={'$25'} button={'Buy Now'} name={'premium'}/>
                        <Premium website={'Website (20 pages max.)'} events={'Events management'} chat={'Chat'}
                                            member={'Membership Management (15 members max.)'} fundraising={'Fundraising'} jobs={'Jobs Posting'} surveys={'Surveys'}
                                            advertising={'Advertising'} tontine={"Tontine"}/>
                    </Grid>
                    <Grid item={true} xs={12} sm={6} md={3}>
                        <AssoPricingCard image={ultimate} alt={'ultimate'} title={'Ultimate'} price={'$75'} button={'Buy Now'} name={'ultimate'}/>
                        <Ultimate website={'Website (unlimited pages)'} events={'Events management'} chat={'Chat'}
                                            member={'Membership Management (unlimited)'} fundraising={'Fundraising'} jobs={'Jobs Posting'} surveys={'Surveys'}
                                            advertising={'Advertising'} tontine={"Tontine"} forms={'Forms Center'} accounting={'Accounting Suite'} support={'Technical Support 24/7'}/>
                    </Grid>
                    <Grid item={true} xs={12} sm={6} md={3}>
                        <AssoPricingCard image={small_business} alt={'small_business'} title={'Small Business'} price={'$50'} button={'Buy Now'} name={'small_business'}/>
                        <SmallBusiness surveys={'Surveys'} forms={'Forms Center'} accounting={'Accounting Suite'} support={'Technical Support 24/7'}/>
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )
}

export default withStyles(styles)(AssoPricing);
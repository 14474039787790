import React, {Fragment} from 'react'
import {Container, Grid, Typography, Button} from "@material-ui/core";
import {withStyles} from "@material-ui/core";
import Grids from '../components/abstractions/Grids'
import {styles} from "../common/styles";
import { MainWebsiteComputer } from "../common/images";

const MoreThan = (props) => {
    const {classes} = props;
    const handleCopyRight=(e)=> {
        e.preventDefault();
        return false;
    };
    return (
        <Fragment>
            <img src={MainWebsiteComputer} alt={"Computer"} className={classes.computerImg} onContextMenu={(e) => {handleCopyRight(e)}} onMouseDown={(e) => e.preventDefault()}/>
            <Container maxWidth={'xl'}>
                <Grids type={'row'} className={classes.more_than}>
                    <Grid item={true} xs={12} sm={6} md={4}>
                        <Typography variant={'h4'}>
                            More Than An Association or Community
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={6} md={4} >
                        <Typography className={classes.more_than_text}>
                            We hardly worked with community leaders around the globe to carefully draw and design
                            ergonomic interfaces to boost your productivity while having fun with community members.
                        </Typography>
                        <Button variant={'contained'} color={'secondary'} className={classes.more_than_btn} href={'/start'}>
                            Try Free Version
                        </Button>
                    </Grid>
                </Grids>
            </Container>
        </Fragment>

    )
}

export default withStyles(styles)(MoreThan);
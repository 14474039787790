import {fade} from "@material-ui/core/styles";
import 'typeface-work-sans'

export const styles = theme => ({
    /***  Avatar Example  ***/

    root: {
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            backgroundColor: '#fff',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        [theme.breakpoints.down('md')]: {
            backgroundColor: '#fff'
        },
    },
    onlineAvatar: {
        marginTop: 8,
        letterSpacing: 1.25,
        color: "#303238",
    },

    /***  404 Page  ***/

    page404: {
        [theme.breakpoints.up('sm')]: {
            backgroundColor: '#fff',
            position: 'absolute',
            top: '48%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '512px'
        },
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#fff',
            position: 'absolute',
            top: '48%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '256px'
        },
    },
    nowhere: {
        textAlign: 'center',
        color: '#303238',
        fontFamily: 'Montserrat',
        fontWeight: 200,
        letterSpacing: 4,
    },
    nowheres: {
        textAlign: 'center',
        color: '#303238d0',
        fontFamily: 'Montserrat',
        fontStyle: "italic",
        fontWeight: 700,
        letterSpacing: 1,
    },
    nowherelink: {
        textAlign: 'center',
    },
    allTextDecoration: {
        textDecoration: 'none',
        color: 'rgba(0,0,0, 0.54)',
        transition: "ease-in-out 0.1s",
        fontFamily: 'Work Sans',


        "&:hover": {
            color: '#0394ff',
        },

        "&:active": {
            color: '#0452d2',
            borderBottomColor: "#0452d2",
        },
    },
    allTextDecoration404: {
        textDecoration: 'none',
        paddingBottom: 4,
        borderBottomColor: "#0394ff",
        borderBottomWidth: "2px",
        borderBottomStyle: "solid",
        color: '#303238f0',
        transition: "ease-in-out 0.1s",
        fontFamily: 'Work Sans',
        fontWeight: 500,

        "&:hover": {
            color: '#0394ff',
        },

        "&:active": {
            color: '#0452d2',
            borderBottomColor: "#0452d2",
        },
    },

    /***  Navbar  ***/

    grow: {
        flexGrow: 1,
    },
    app_root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    titleBar: {
        marginLeft: theme.spacing(2),
        //color: '#596361',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(8),
        },
    },
    elementBar: {
        marginTop: theme.spacing(1.5),
        //color: '#596361',

        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            display: 'none',
        },
    },
    langElementBar: {
        marginTop: theme.spacing(1.5),
       //color: '#596361',

        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            display: 'none',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionDesktopBar: {
        display: 'flex',
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(8),
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },

    /***  Jumbotron (Hero Section)  ***/

    asso_jumbotron: {
        fontFamily: "Montserrat",
        [theme.breakpoints.up('lg')]: {
            textTransform: 'capitalize',
            fontWeight: 800,
            fontSize: '5.5rem',
            color: '#1e2128',
            lineHeight: 0.95,
            marginTop: theme.spacing(10),
        },
        [theme.breakpoints.down('md')]: {
            textTransform: 'capitalize',
            fontWeight: 800,
            fontSize: '4rem',
            color: '#1e2128',
            lineHeight: 0.95,
            marginTop: theme.spacing(8),
        },
        [theme.breakpoints.down('sm')]: {
            textTransform: 'capitalize',
            fontWeight: 700,
            fontSize: '56px',
            color: '#1e2128',
            lineHeight: 0.95,
        },
        [theme.breakpoints.down('xs')]: {
            textTransform: 'capitalize',
            fontWeight: 700,
            fontSize: '36px',
            color: '#1e2128',
            lineHeight: 0.95,
            marginTop: theme.spacing(4),
        },
    },
    container_jumbotron: {
        [theme.breakpoints.up('lg')]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(12),
        },
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(8),
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(8),
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(4),
        },
    },
    heroButton: {
        fontSize: "1.2rem",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1rem",
        },
    },

    /***  "More Than" Section  ***/

    more_than: {
        backgroundColor: '#0452d2',
        color: '#fff',
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(12),
            marginBottom: theme.spacing(8)
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(12),
            padding: theme.spacing(4)
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(12),
            padding: theme.spacing(4)
        },
    },
    more_than_text: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(2),
        },
    },
    more_than_btn: {
        marginTop: 16,
    },
    computerImg: {
        [theme.breakpoints.up('lg')]: {
            width: 2000,
            transform: "translate(224px, 0)",
        },
        [theme.breakpoints.down('md')]: {
            width: 1500,
        },
        [theme.breakpoints.down('sm')]: {
            width: 1200,
        },
        [theme.breakpoints.down('xs')]: {
            width: 800,
        },
    },
    /***  Template Section  ***/

    template_root: {
        backgroundColor: '#f0f0f0',
        color: '#303238',

        [theme.breakpoints.up('lg')]: {
            padding: "96px 72px",
        },
        [theme.breakpoints.down('md')]: {
            padding: "72px 56px",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "56px 32px",
        },
        [theme.breakpoints.down('xs')]: {
            padding: "32px 24px",

        },
    },

    template_title: {
        [theme.breakpoints.up('lg')]: {
            marginBottom: 16,
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: 32,
        },
    },

    template_titleText: {
        color: "#1e2128",
    },

    template_titles: {
        color: "#1e2128",
        lineHeight: 1.2,
        margin: "12px 0 8px 0",
    },

    template_hr: {
        color: '#f5f5f5',
    },

    template_text: {
        [theme.breakpoints.up('lg')]: {
            padding: "0 32px 32px 0",
        },
        [theme.breakpoints.down('md')]: {
            padding: "0 16px 32px 16px",
        },
        [theme.breakpoints.down('xs')]: {
            padding: "0 0 32px 0",
        },
    },



    /***  Professional Features Section  ***/

    proTitle: {
        [theme.breakpoints.up('lg')]: {
            padding: "20px 32px 32px 135px",
        },
        [theme.breakpoints.down('md')]: {
            padding: "0 16px 32px 16px",
        },
        [theme.breakpoints.down('xs')]: {
            padding: "0 0 32px 0",
        },
    },
    proEvent: {
        [theme.breakpoints.up('lg')]: {
            margin: "10px 0 0 0px",
        },
        [theme.breakpoints.down('md')]: {
            margin: "20px 0 0 0",
        },
        [theme.breakpoints.down('xs')]: {
            margin: "0 0 0 0",
        },
    },
    proBudget: {
        [theme.breakpoints.up('lg')]: {
            margin: "200px 0 0px 0px",
        },
        [theme.breakpoints.down('md')]: {
            margin: "100px 0 0 0",
        },
        [theme.breakpoints.down('xs')]: {
            margin: "0 0 0 0",
        },
    },

    proTickets: {
        [theme.breakpoints.up('lg')]: {
            margin: "300px 0 0 0px",
        },
        [theme.breakpoints.down('md')]: {
            margin: "200px 0 0 0",
        },
        [theme.breakpoints.down('xs')]: {
            margin: "0 0 0 0",
        },
    },

    proSurveys: {
        [theme.breakpoints.up('lg')]: {
            margin: "100px 0 0 0px",
        },
        [theme.breakpoints.down('md')]: {
            margin: "80 0 0 0",
        },
        [theme.breakpoints.down('xs')]: {
            margin: "0 0 0 0",
        },
    },

    proChat: {
        [theme.breakpoints.up('lg')]: {
            margin: "300px 0 0 0px",
        },
        [theme.breakpoints.down('md')]: {
            margin: "200 0 0 0",
        },
        [theme.breakpoints.down('xs')]: {
            margin: "0 0 0 0",
        },
    },



    proFundraising: {
        [theme.breakpoints.up('lg')]: {
            margin: "30px 0 0 0px",
        },
        [theme.breakpoints.down('md')]: {
            margin: "20 0 0 0",
        },
        [theme.breakpoints.down('xs')]: {
            margin: "0 0 0 10px",
        },
    },

    proDomain: {
        [theme.breakpoints.up('lg')]: {
            margin: "200px 0 0 0px",
        },
        [theme.breakpoints.down('md')]: {
            margin: "100 0 0 0",
        },
        [theme.breakpoints.down('xs')]: {
            margin: "0 0 0 0",
        },
    },

    proMembership: {
        [theme.breakpoints.up('lg')]: {
            margin: "300px 0 0 0px",
        },
        [theme.breakpoints.down('md')]: {
            margin: "200 0 0 0",
        },
        [theme.breakpoints.down('xs')]: {
            margin: "0 0 0 0",
        },
    },

    proRoles: {
        [theme.breakpoints.up('lg')]: {
            margin: "300px 0 50px 0px",
        },
        [theme.breakpoints.down('md')]: {
            margin: "200px 0 30px 0",
        },
        [theme.breakpoints.down('xs')]: {
            margin: "0 0 0 0",
        },
    },

    proTontine: {
        [theme.breakpoints.up('lg')]: {
            margin: "30px 0 0 0px",
        },
        [theme.breakpoints.down('md')]: {
            margin: "20 0 0 0",
        },
        [theme.breakpoints.down('xs')]: {
            margin: "0 0 0 0",
        },
    },



    /***  Accounting website Section  ***/

        /*** NOTHING DONE HERE     ***/



    /***  Accounting website Section  ***/

    root_footer: {
       padding: '75px 0 30px 0',
        [theme.breakpoints.down('sm')]: {
            padding: "30px 0 0 30px",
        },
        [theme.breakpoints.down('xs')]: {
            margin: "0 0 0 0",
        },
    },

    subtitle: {
        color: "#303238e0",
    },

    footer_support: {
        [theme.breakpoints.up('md')]: {
            marginRight: "50px",
        },
        [theme.breakpoints.down('sm')]: {
            margin: "40px 0 24px 0",
        },
    },

    footer_titi_text: {
        margin: '15px 0 15px 0',
    },




    /***  Features page  ***/
    features_root: {
        color: '#fff',
        backgroundColor: '#0452d2',
        height: "initial",
    },
    features_title: {
        margin: '96px 0 16px 0',

        [theme.breakpoints.down('md')]: {
            fontSize: 24,
        },
    },
    features_text: {
        color: "#fffffff0",
        margin: '0 0 24px 0',

        [theme.breakpoints.down('md')]: {
            fontSize: 16,
            lineHeight: 1.4,
        },
    },
    features_try: {
        margin: '0 0 94px 0',
    },
    feature_content: {
        margin: '24px 0 24px 0',
    },
    feature_header: {
        margin: '40px 0 0 0',
        [theme.breakpoints.down('md')]: {
            margin: '30px 0 0 0',
        },
    },



    /***  Pricing page  ***/
    pricing_title: {
        margin: '96px 0 16px 0',

        [theme.breakpoints.down('md')]: {
            fontSize: 24,
        },
    },
    pricing_text: {
        color: "#fffffff0",
        margin: '0 0 80px 0',

        [theme.breakpoints.down('md')]: {
            fontSize: 16,
            lineHeight: 1.4,
        },
    },
    pricing_root: {
        color: '#fff',
        backgroundColor: '#0452d2',
        height: "initial",
        margin: '0 0 100px 0'
    },




    /***  Sign up page  ***/
    recap: {
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            //margin: "150px 0 0 0",
        },
        [theme.breakpoints.down('md')]: {
            //margin: "80px 0 0 0",

        },
        [theme.breakpoints.down('sm')]: {
            //margin: "20px 0 0 0",

        },
        [theme.breakpoints.down('xs')]: {

        },
    },
    steps: {
        padding: '20px 0 0 20px',
        borderColor: '#0452d2',

        [theme.breakpoints.up('lg')]: {
            width: '80%'
        },
        [theme.breakpoints.down('md')]: {
            width: '80%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        [theme.breakpoints.down('xs')]: {

        },
    },

    infoRecap: {
        borderColor: '#0452d2',
    },

    recap_title: {
        [theme.breakpoints.up('lg')]: {
            marginLeft: 190,

        },
        [theme.breakpoints.down('md')]: {
            marginLeft: 160,

        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 130,

        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 90,
        },
    },

    recap_paper: {
       backgroundColor: '#0452d2',
       color: '#fff',
       margin: '5px 0 20px 0',
       minHeight: 'initial',
    },

    important_title: {
        color: '#f00',
        fontWeight: '700'
    },
    image_recap: {
        [theme.breakpoints.up('lg')]: {
            marginLeft: 170,

        },
        [theme.breakpoints.down('md')]: {
            marginLeft: 140,

        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 100,

        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 70,
        },
    },

    root_create: {

        [theme.breakpoints.up('lg')]: {
            marginTop: -175,

        },
        [theme.breakpoints.down('md')]: {
            marginTop: -175,

        },
        [theme.breakpoints.down('sm')]: {
            marginTop: -150,

        },
        [theme.breakpoints.down('xs')]: {

        },
    },
    create_root: {
        color: '#fff',
        backgroundColor: '#0452d2',
        height: "300px",
        margin: '0 0 100px 0'
    },

})
import React from 'react'
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from "@material-ui/icons/Check";
import green from "@material-ui/core/colors/green";
import ListItemText from "@material-ui/core/ListItemText";

const CheckedRecap = props => {
    const {chosen} = props;
    return (
            <ListItem>
                <ListItemIcon>
                    <CheckIcon style={{ color: green[500] }} />
                </ListItemIcon>
                <ListItemText id="website" primary={chosen} />
            </ListItem>
    )
}

export default CheckedRecap;
const AVATAR = '/';
const CREATE_ASSOCIATION = '/asso';
const ADMIN = '/admin';
const FEATURES = '/features';
const PRICING = '/pricing'
const START = '/start'


export {
   AVATAR, CREATE_ASSOCIATION, ADMIN, FEATURES, PRICING, START
}
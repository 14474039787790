import React from 'react'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {template_1, template_2, template_3, template_4} from '../../common/images'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = {
    root: {
        position: 'relative',
        border: "1px #1e2128 solid",
        borderRadius: 2,
        backgroundColor: '#fff'
    },
    slide: {
        maxWidth: "100%",
        color: '#fff',
    },
};

class TemplateShows extends React.Component {
    state = {
        index: 0,
    };

    handleChangeIndex = index => {
        this.setState({
            index,
        });
    };

    handleCopyRight=(e)=> {
        e.preventDefault();
        return false;
    };

    render() {
        const { index } = this.state;

        return (
            <div style={styles.root}>
                <AutoPlaySwipeableViews index={index} onChangeIndex={this.handleChangeIndex}>
                    <img src={template_1} alt={'template 1'} style={styles.slide} onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()}/>
                    <img src={template_2} alt={'template 2'} style={styles.slide} onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()}/>
                    <img src={template_3} alt={'template 3'} style={styles.slide} onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()}/>
                    <img src={template_4} alt={'template 4'} style={styles.slide} onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()}/>
                </AutoPlaySwipeableViews>
            </div>
        );
    }
}

export default TemplateShows;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
//import {ourPricing} from '../associations/PricingContext'
import {useRecoilState} from "recoil";
import {differentPricing} from "../../GlobalState";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        borderColor: '#0452d2'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    buyButton: {
        width: '60%',
        margin: '0 0 15px 50px'
    }
});

export default function AssoPricingCard(props) {
    const {image, alt, title, price, button, name} = props;
    const classes = useStyles();
    const [myPrice, setPrice] = useRecoilState(differentPricing)
    //const [myPrice, setPrice] = useState(ourPricing)


    const setPricingContext = (name) => {
        switch (name) {
            case 'free':
                setPrice({...myPrice, free: true, select: true});
                console.log("You have selected free option")
                break;
            case 'starter':
                setPrice({...myPrice, starter: true, select: true});
                console.log("You have selected starter option")
                break;
            case 'premium':
                setPrice({...myPrice, premium: true, select: true});
                console.log("You have selected premium option")
                break;
            case 'ultimate':
                setPrice({...myPrice, ultimate: true, select: true});
                console.log("You have selected ultimate option")
                break;
            case 'small_business':
                setPrice({...myPrice, small_business: true, select: true});
                console.log("You have selected small business option")
                break;
            default:
                setPrice({...myPrice, ultimate: true, select: true});
        }
    }


    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom align={'center'}>
                    <img src={image} alt={alt} width={200}/>
                </Typography>
                <Typography variant="h5" component="h2" align={'center'}>
                    {title}
                </Typography>
                <Typography className={classes.pos} color="textSecondary" variant={'h3'} align={'center'}>
                    {price}
                </Typography>
                <Typography variant="body2" component="p" align={'center'}>
                    per month, billed yearly
                </Typography>
            </CardContent>
            <CardActions>
                    <Button size="medium"
                            href={'/start'}
                            variant={'contained'}
                            color={'primary'}
                            disableElevation={true}
                            className={classes.buyButton}
                            name={name}
                            onClick={() => {
                                setPricingContext(name)
                            }}
                    >
                        {button}
                    </Button>
            </CardActions>
        </Card>
    );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
        maxWidth: 500,
        margin: 20,
        color: '#1e2128',
        backgroundColor: 'transparent'
    },
});

export default function CardDetails(props) {
    const classes = useStyles();
    const {myTitle, content} = props;
    return (
        <Card className={classes.root} elevation={0}>
            <CardActionArea disabled={true}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {myTitle}
                    </Typography>
                    <Typography variant="body2" color="textPrimary" component="p">
                        {content}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <hr/>
        </Card>
    );
}
import {createMuiTheme} from "@material-ui/core";
import 'typeface-montserrat'
import 'typeface-work-sans'

export const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#0452d2',
            dark: '#142dbd',
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            //light: '#0066ff',
            main: '#0394ff',
            dark: '#0982ef',
            //contrastText: '#ff0707',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
        text: {
            primary: '#303238f0',
            secondary: '#596361',
        },
        background: {
            paper: '#fff',
            default: '#fff'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    },
    typography: {
        button: {
            fontSize: '1rem',
            fontWeight: 500,
            textTransform: "initial",
        },
        h1: {
            fontFamily: 'Montserrat',
            fontWeight: 900, // Placeholder
            //textShadow: "0 2px 0 #0394ff",
        },
        h2: {
            fontFamily: 'Montserrat',
            fontWeight: 700, // Placeholder
        },
        h3: {
            fontFamily: 'Montserrat',
            fontWeight: 700, // Placeholder
        },
        h4: {
            fontFamily: 'Montserrat',
            fontWeight: 700, // Placeholder
        },
        h5: {
            fontFamily: 'Montserrat',
            fontWeight: 600, // Placeholder
        },
        h6: {
            fontFamily: 'Montserrat',
            fontWeight: 600, // Placeholder
        },
        fontFamily: 'Work Sans',
    },
    overrides: {
        MuiButton: {
            root: {
                fontSize: '1rem',
                color: '#ff0707'
            },
            textSizeSmall: {
                fontSize: '3rem',
            },
            text: {
                color: '#ff0707'
            },
        },
        MuiAppBar: {
            colorPrimary: {
                color: "#e2e9e8"
            }
        },
        MuiTextField: {
            root: {

                //backgroundColor: '#fff',
            }
        },
        MuiTab: {
            textColor: '#fff'
        }
    },
})
import React from 'react';
import {Grid} from "@material-ui/core";

const Grids = (props) => {
    let gridContainer = null;

    switch (props.type){
        case ("row"):
            gridContainer = <Grid container direction="row" justify="center" alignItems="center" {...props} />;
            break;
        case ("start"):
            gridContainer = <Grid container direction="row" justify="flex-start" alignItems="center" {...props}/>;
            break;
        case ("baseline") :
            gridContainer = <Grid container direction="row" justify="flex-start" alignItems="baseline" {...props}/>;
            break;
        case ("column"):
            gridContainer = <Grid container direction="column" justify="center" alignItems="center" alignContent={'center'} {...props}/>;
            break;
        case ("columnL"):
            gridContainer = <Grid container direction="column" justify="flex-start" alignItems="flex-start" alignContent={'flex-start'} {...props}/>;
            break;
        case ("rowS"):
            gridContainer = <Grid container direction="row" justify="center" alignItems="flex-start" {...props}/>;
            break;
        default:
            gridContainer = null;
    }

    return gridContainer;
};

export default Grids;
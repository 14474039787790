import React, {useRef} from 'react'
import InputLabel from "@material-ui/core/InputLabel";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";


const Step1 = ({myOrganization, handleChange}) => {
    const name = useRef()
    const domain = useRef()
    return (
        <div>
            <FormControl  variant="filled" margin={'dense'} fullWidth={true}>
                <InputLabel htmlFor="filled-adornment-password">Organization Name</InputLabel>
                <FilledInput
                    ref={name}
                    onChange={handleChange('name')}
                    value={myOrganization.name}
                    required={true}
                    type={'text'}
                />
            </FormControl>
            <FormControl  variant="filled" margin={'dense'} fullWidth={true}>
                <InputLabel htmlFor="filled-adornment-password">SubDomain</InputLabel>
                <FilledInput
                   ref={domain}
                    placeholder={'for eg. nurse'}
                    onChange={handleChange('domain')}
                    value={myOrganization.domain}
                    required={true}
                    type={'text'}
                />
            </FormControl>
        </div>
    )
}

export default Step1;

import React, {useRef, useEffect, Fragment} from 'react'
import {Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {useRecoilState, useRecoilValue} from "recoil/dist";
import {getPricing, getValueSelected} from '../../GlobalState'


const Step3 = props => {
  const myPrice = useRecoilValue(getPricing);
  const [selected, setSelected] = useRecoilState(getValueSelected)
   const option = useRef() ;
  useEffect(() => {
      const {current} = option;
      const handleChange = event => {
          setSelected({...selected, option: event.target.value});
      };
      console.log("You have selected " + selected.option);

      return () => {
          current.addEventListener('change', handleChange)
      }
  })

    return (
        <Fragment>
            <Typography>
                Payment info with Stripe API
            </Typography>
            <TextField
                id="filled-select-currency-native"
                ref={option}
                select
                fullWidth={true}
                margin={'dense'}
                label="Option"
                value={selected.option}
                //onChange={handleChange}
                SelectProps={{
                    native: true,
                }}
                variant="filled"
            >
                {Object.keys(myPrice).map((price) => (
                    <option key={price} value={price}>
                        {price}
                    </option>
                ))}
            </TextField>
        </Fragment>
    )
}

export default Step3;
import React, {Component} from 'react'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {withStyles} from "@material-ui/core";
import {styles} from "../../common/styles";
import {dashChat, dashSurvey, dashRoles} from '../../common/images'


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default withStyles(styles)(class ProductPresentation extends Component {
    state = {
        index: 0,
    };
    handleChangeIndex = index => {
        this.setState({
            index,
        });
    };
    handleCopyRight=(e)=> {
        e.preventDefault();
        return false;
    };
    render() {
        const { index } = this.state;
        const {classes} = this.props;

        return <div>
            <AutoPlaySwipeableViews index={index} onChangeIndex={this.handleChangeIndex}>
                <img src={dashSurvey} alt={'survey'} onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()} className={classes.root_product_presentation_image}/>
                <img src={dashRoles} alt={'roles'} onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()} className={classes.root_product_presentation_image}/>
                <img src={dashChat} alt={'chat'}  onContextMenu={this.handleCopyRight} onMouseDown={(e) => e.preventDefault()} className={classes.root_product_presentation_image}/>
            </AutoPlaySwipeableViews>
        </div>;
    }
})


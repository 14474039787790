import React from 'react'
import {Container, Grid, Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/core";
import {styles} from "../../common/styles";
import Grids from '../abstractions/Grids'
import CardPresentation from "./CardPresentation";
import {
    chat,
    custom_domain,
    fundraising,
    membership,
    forms,
    tontine,
    events,
    roles,
    surveys,
    jobs
} from '../../common/images'


const ProfessionalFeatures = (props) => {
    const {classes} = props;
    return (
        <div>
            <Container maxWidth={'xl'} >
                <Grid item={true} xs={12} sm={8} md={8} lg={6} className={classes.proTitle}>
                    <Typography variant={'overline'}>
                        <b>Professional Features for your app</b>
                    </Typography>
                    <Typography variant={'h6'}>
                        Including managing events, budgets, tickets, fundraising, surveys,
                        membership, tontine and chat. Easier than ever to define roles and setup permissions
                    </Typography>
                </Grid>
            </Container>
            <Container>
                <Grids type={'row'}  spacing={1}>
                    <Grid  xs={12} sm={6} md={6}  item={true} className={classes.proEvent}>
                        <CardPresentation myTitle={'Events, Budget and Tickets'} image={events} content={'We power you with great tools to have successful events.'}/>
                    </Grid>
                    <Grid  xs={12} sm={6} md={6} lg={6} item={true} className={classes.proBudget}>
                        <CardPresentation myTitle={'Chat'} image={chat} content={'Chat and laugh with community members.'}/>
                    </Grid>
                </Grids>
                <Grid container  spacing={1}>
                    <Grid  xs={12} sm={6} md={6} item={true} className={classes.proTickets}>
                        <CardPresentation myTitle={'Custom Domain'} image={custom_domain} content={'Get a professional domain name that matches your association / community.'}/>
                    </Grid>
                    <Grid  xs={12} sm={6} md={6} item={true} className={classes.proSurveys}>
                        <CardPresentation myTitle={'Surveys'} image={surveys} content={'Drag and drop surveys, modify and send them.'}/>
                    </Grid>
                </Grid>
                <Grid container  spacing={1}>
                    <Grid  xs={12} sm={6} md={6} item={true} className={classes.proChat}>
                        <CardPresentation myTitle={'Tontine'} image={tontine} content={'Tontine, your complete community financial services to help each other and grow your community. More community than a business.'}/>
                    </Grid>
                    <Grid  xs={12} sm={6} md={6} item={true} className={classes.proFundraising}>
                        <CardPresentation myTitle={'Fundraising'} image={fundraising} content={'Create a fundraiser for what matters to you.'}/>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid  xs={12} sm={6} md={6} item={true} className={classes.proDomain}>
                        <CardPresentation myTitle={'Membership'} image={membership} content={'Management membership easily with nice interfaces.'}/>
                    </Grid>
                    <Grid  xs={12} sm={6} md={6} item={true} className={classes.proMembership}>
                        <CardPresentation myTitle={'Roles and Permissions'} image={roles} content={'Define roles easily from all permissions already in place.'}/>
                    </Grid>
                </Grid>
                <Grids type={'row'}  spacing={1} container>
                    <Grid  xs={12} sm={6} md={6} item={true} className={classes.proRoles}>
                        <CardPresentation myTitle={'Jobs Posting'} image={jobs} content={'Nice interfaces to list and apply for jobs for members only.'}/>
                    </Grid>
                    <Grid  xs={12} sm={6} md={6} item={true} className={classes.proTontine}>
                        <CardPresentation myTitle={'Forms And Documents'} image={forms} content={'Create forms easily or edit 600+ forms templates for all kinds of purposes (Contract, Agreements, docs and more)'}/>
                    </Grid>
                </Grids>
            </Container>
        </div>
       )
}

export default withStyles(styles)(ProfessionalFeatures);
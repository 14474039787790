import React from 'react'
import {Content, AssoPublicNavBar, Jumbotron, Footer, Templates, ProfessionalFeatures, Accounting} from '../components'
import MoreThan from './MoreThan'

const HomePage = () => {
    return (
        <div style={{overflowX: "hidden"}}>
            <AssoPublicNavBar/>
            <Content>
                <Jumbotron/>
            </Content>
            <div>
                <MoreThan/>
            </div>
            <Templates/>
            <ProfessionalFeatures/>
            <Accounting/>
            <Footer/>
        </div>
    )
}

export default HomePage;
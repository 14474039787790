import React from 'react';
import {theme} from "./common/styles";
import {ThemeProvider} from "@material-ui/styles";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {RecoilRoot} from "recoil/dist";

import {HomePage, Page404, Features, Pricing, SignUp} from './pages'
import * as routes from './routes'


const App = () => {
  return (
      <Router>
        <RecoilRoot>
            <ThemeProvider theme={theme}>
               <Switch>
                   <Route exact={true} path={routes.AVATAR} component={HomePage}/>
                   <Route exact={true} path={routes.FEATURES} component={Features}/>
                   <Route exact={true} path={routes.PRICING} component={Pricing}/>
                   <Route exact={true} path={routes.START} component={SignUp}/>
                   <Route  component={Page404} exact={true}/>
               </Switch>
            </ThemeProvider>
        </RecoilRoot>
      </Router>
  );
}

export default App;

import React from 'react'
import {AssoFeatures, AssoPublicNavBar, Footer} from '../components'


const Features = () => {
    return (
        <div style={{ overflowX: 'hidden'}}>
            <AssoPublicNavBar/>
            <AssoFeatures/>
            <Footer/>
        </div>
    )
}

export default Features;
